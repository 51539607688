import { Center, OrbitControls } from '@react-three/drei';
import { useLoader, useThree } from '@react-three/fiber';
import { useCallback, useEffect, useMemo, useState } from 'react';

import * as THREE from 'three';
// import loadingGif from '../../../landing/loadingAnimation.gif';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import './model3d.scss';
import { ARFeature } from './AR';
// import { useIsMobile } from 'common/hooks';

const MIME_MODEL_3D = ['.fbx', '.glb', '.gltf', '.obj'];

function findDuplicateExtensions(filename, extensions) {
  const fileExtension = filename
    .toLowerCase()
    .substring(filename.lastIndexOf('.'));
  return extensions.find((ext) => ext === fileExtension);
}

function setCameraPositionToFitObject(
  camera,
  object,
  canvasWidth,
  canvasHeight
) {
  if (!object) return;
  // Tính khoảng cách từ camera đến đối tượng
  const objectSize = new THREE.Box3()
    .setFromObject(object)
    .getSize(new THREE.Vector3());
  const objectDistance = Math.max(objectSize.x, objectSize.y, objectSize.z);

  // Tính khoảng cách dự phòng (padding) để đảm bảo đối tượng không bị tràn ra ngoài
  const padding = objectDistance / 2;

  // Tính khoảng cách tới camera dựa trên góc nhìn của camera (thường là FOV)
  const fov = camera.fov * (Math.PI / 180);
  const cameraDistance = objectDistance / 2 / Math.tan(fov / 2);

  // Tính khoảng cách z (từ camera tới đối tượng) bằng cách cộng khoảng cách dự phòng và khoảng cách tới camera
  const distanceZ = padding + cameraDistance;

  // Đặt vị trí camera
  camera.position.set(0, 0, distanceZ);

  // // Đảm bảo camera nhìn vào điểm giữa của đối tượng
  const center = new THREE.Vector3();
  object.getWorldPosition(center);
  camera.lookAt(center);

  // Điều chỉnh tỷ lệ khung hình (aspect ratio) dựa trên kích thước của canvas
  const aspectRatio = canvasWidth / canvasHeight;
  camera.aspect = aspectRatio;
  camera.updateProjectionMatrix();
}

const GLBModel = ({ media, setModel }) => {
  const gltf = useLoader(GLTFLoader, media.modelUrl);
  const model = gltf.scene;
  model.scale.set(1, 1, 1);

  useEffect(() => {
    setModel(model);
  }, [model, setModel]);

  return <primitive object={gltf.scene} />;
};

const OBJModel = ({ media, setModel }) => {
  const obj = useLoader(OBJLoader, media.modelUrl);
  obj.scale.set(1, 1, 1);

  useEffect(() => {
    setModel(obj);
  }, [obj, setModel]);

  return <primitive object={obj} />;
};

const FBXModel = ({ media, setModel }) => {
  const fbx = useLoader(FBXLoader, media.modelUrl);
  fbx.scale.set(1, 1, 1);

  useEffect(() => {
    setModel(fbx);
  }, [fbx, setModel]);

  return <primitive object={fbx} />;
};

const ModelContainer = ({ loader, media, Model }) => {
  const { camera, size } = useThree();

  useEffect(() => {
    if (media.modelCoordinate) {
      camera.position.set(
        media.modelCoordinate.x,
        media.modelCoordinate.y,
        media.modelCoordinate.z
      );
    } else {
      setCameraPositionToFitObject(camera, Model, size.width, size.height);
    }
  }, [Model, camera, media.modelCoordinate, size.height, size.width]);

  return (
    <>
      <group position={[0, 0, 0]}>
        <Center>
          {loader(findDuplicateExtensions(media.modelName, MIME_MODEL_3D))}
        </Center>
      </group>
      <OrbitControls />
    </>
  );
};

export const PopupModel3D = ({ media }) => {
  const [Model, setModel] = useState();
  // const isMobile = useIsMobile();

  const loader = useCallback(
    (extension) => {
      const test = {
        '.glb': <GLBModel media={media} setModel={setModel} />,
        '.obj': <OBJModel media={media} setModel={setModel} />,
        '.fbx': <FBXModel media={media} setModel={setModel} />,
        '.gltf': <GLBModel media={media} setModel={setModel} />,
      };
      return test[extension];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [media.modelUrl]
  );

  // eslint-disable-next-line no-unused-vars
  const model = useMemo(
    () => <ModelContainer loader={loader} media={media} Model={Model} />,
    [loader, media, Model]
  );

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        // marginTop: '40px',
      }}
    >
      <ARFeature media={media} />
      {/* {isMobile ? (
      ) : (
        <>
          <div
            className="loading_model_spinner"
            style={{
              background: 'transparent',
              display: `${Model ? 'none' : 'flex'}`,
            }}
          >
            <div className="loading-logo" style={{ opacity: '1' }}>
              <img
                style={{ width: '10%' }}
                src={loadingGif}
                alt="VR Loading Logo"
              />
            </div>
          </div>
          <Canvas
            shadows
            camera={{ far: 10000 }}
            gl={{ alpha: true, antialias: true, autoClear: false }}
          >
            <Suspense fallback={null}>
              {model}
              <ambientLight intensity={2} />
              <directionalLight
                position={[0, 10, 0]}
                shadow-mapSize={1024}
                castShadow
                intensity={2}
              />
            </Suspense>
          </Canvas>
        </>
      )} */}
    </div>
  );
};
