import { AquaRightArrow } from 'components/icons/AquaIcons';
import React from 'react';
import './navInfo.scss';

const NavInfo = ({ menuName, sceneName }) => {
  return (
    <div className="aqua-nav-info">
      <p className="aqua-nav-info-title">{menuName}</p>
      <div className="aqua-nav-info-arrow">
        <AquaRightArrow />
      </div>
      <p className="aqua-nav-info-title">{sceneName}</p>
    </div>
  );
};

export default NavInfo;
