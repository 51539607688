import { ExpandIcon } from 'components/icons';
import React, { useState } from 'react';
import { limitLetters } from 'utils/limitLetters';
import ViewPointMapPlan from './ViewPointMapPlan';
import './mapPlanPreview.scss';

const MapPlanPreview = (props) => {
  const {
    floorMaps,
    mapShowed,
    isActiveMap,
    setMapShowed,
    setIsActiveMap,
    currentPano,
    selectSceneById,
  } = props;

  const [openDropDown, setOpenDropDown] = useState(false);

  const handleSelectMap = (item) => {
    setMapShowed(item);
  };

  return (
    <div
      className={`mapfloorplan_container mapfloorplan_container-aacorp
        ${!isActiveMap ? 'open-aacorp' : ''}
      `}
    >
      {mapShowed && (
        <>
          {floorMaps?.length > 1 && (
            <div className="map_header">
              <div
                className="map_dropdown"
                onClick={() => setOpenDropDown(!openDropDown)}
              >
                <span>{limitLetters(mapShowed.image.name, 15)}</span>
                <i className="map_arrow down"></i>
                {openDropDown && (
                  <div
                    className={`map_dropdown_options ${
                      openDropDown ? 'show' : ''
                    }`}
                  >
                    <ul className="dropdown_items">
                      {floorMaps?.map((item, index) => (
                        <li onClick={() => handleSelectMap(item)} key={index}>
                          {limitLetters(item.image.name, 10)}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className={`icon icon-aquaStyle`}>
                <ExpandIcon />
              </div>
            </div>
          )}
          <div className="map_image">
            <img src={mapShowed.image.url} alt={mapShowed.image.name} />
            {mapShowed.objects?.map((viewpoint) => {
              return (
                <React.Fragment key={viewpoint.id}>
                  <ViewPointMapPlan
                    viewpoint={viewpoint}
                    currentPano={currentPano}
                    selectSceneById={selectSceneById}
                  />
                </React.Fragment>
              );
            })}
          </div>

          <div
            className="mapfloorplan_close"
            onClick={() => setIsActiveMap(true)}
          >
            <button className="mapfloorplan_close-btn">&times;</button>
          </div>
        </>
      )}
    </div>
  );
};

export default MapPlanPreview;
