import './infoTagStyle.scss';

const InfoTagStyle = ({ text, onClick }) => {
  return (
    <div className="info-tag-container">
      <div className="info-tag-dot"></div>
      <div className="info-tag-content">
        <div className="info-tag-line"></div>
        <div className="info-tag-content-text" onClick={onClick}>{text}</div>
      </div>
    </div>
  );
};

export default InfoTagStyle;
