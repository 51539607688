import { useSelector } from 'react-redux';
import vizionLogo from 'assets/images/logo/vizion_logo.png';
import './clearSpaceLogo.scss';

const ClearSpaceLogo = () => {
  const tour = useSelector(state => state.tour);
  const tourLogo = tour.logoURL || vizionLogo;

  return <div className="clear-space-logo-container">
    <img src={tourLogo} alt="Logo" />
  </div>;
};

export default ClearSpaceLogo;
