import { toggleAudio } from 'store/actions';
import './resortAudio.scss';
import { useDispatch, useSelector } from 'react-redux';

const ResortAudio = () => {
  const audio = useSelector((state) => state.audio);
  const dispatch = useDispatch();

  const handleToggleAudio = () => {
    dispatch(toggleAudio(!audio.enabled));
  }

  return (
    <div className="resort-audio-container" >
      <div className="audio-icon-box" onClick={handleToggleAudio}>
        <div className="box box1"></div>
        <div className="box box2"></div>
        <div className="box box3"></div>
        <div className="box box4"></div>
        <div className="box box5"></div>
      </div>
    </div>
  );
};

export default ResortAudio;
