import HOTSPOT_TYPE from 'consts/hotspotType.const';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import JumpSpot from './JumpSpot';

const ClearSpaceJumpSpots = () => {
  const groupRef = useRef();
  const currentScene = useSelector((state) => state.currentScene);

  const jumpspots = currentScene?.hotspots?.filter(
    (hs) => hs.type === HOTSPOT_TYPE.SCENE
  );

  return (
    <group ref={groupRef}>
      {jumpspots?.map((jp, index) => (
        <JumpSpot key={index} location={jp.location} />
      ))}
    </group>
  );
};

export default ClearSpaceJumpSpots;
