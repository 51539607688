import { isMobile } from 'react-device-detect';
import Video from './icon-images/picture-frame.png';
import Map1 from './icon-images/address.png';
import Map2 from './icon-images/map-v2.png';
import Location from './icon-images/pin.png';
import GreenPark from './icon-images/bench-tree.png';
import Fullscreen from './icon-images/fullscreen.png';
import AutoRotate from './icon-images/arrow.png';
import House from './icon-images/old-building.png';
import Villa from './icon-images/city.png';
import Share from './icon-images/share.png';
import RightArrow from './icon-images/right.png';
import SceneMenuIcon from './icon-images/list.png';
import LargeMapfloorplanIcon from './icon-images/blueprint.png';

export const AquaVideoIcon = () => {
  return (
    <img
      src={Video}
      alt="aqua-icon"
      style={{ height: 20, filter: 'invert(100%)' }}
    />
  );
};

export const AquaMapfloorplanIconV1 = () => {
  return (
    <img
      src={Map1}
      alt="aqua-icon"
      style={{ height: 20, filter: 'invert(100%)' }}
    />
  );
};

export const AquaMapfloorplanIconV2 = ({ isActive }) => {
  return (
    <img
      className="menu-items-aqua__icon"
      src={Map2}
      alt="aqua-icon"
      style={{
        filter: isActive
          ? 'invert(92%) sepia(11%) saturate(5161%) hue-rotate(314deg) brightness(112%) contrast(106%)'
          : 'invert(100%)',
      }}
    />
  );
};

export const AquaLocationIcon = () => {
  return (
    <img
      src={Location}
      alt="aqua-icon"
      style={{ height: 20, filter: 'invert(100%)' }}
    />
  );
};

export const AquaGreenParkIcon = ({ isActive }) => {
  return (
    <img
      className="menu-items-aqua__icon"
      src={GreenPark}
      alt="aqua-icon"
      style={{
        filter: isActive
          ? 'invert(92%) sepia(11%) saturate(5161%) hue-rotate(314deg) brightness(112%) contrast(106%)'
          : 'invert(100%)',
      }}
    />
  );
};

export const AquaFullscreenIcon = () => {
  return (
    <img
      src={Fullscreen}
      alt="aqua-icon"
      style={{ height: 20, filter: 'invert(100%)' }}
    />
  );
};

export const AquaAutoRotateIcon = () => {
  return (
    <img
      src={AutoRotate}
      alt="aqua-icon"
      style={{ height: 20, filter: 'invert(100%)' }}
    />
  );
};

export const AquaHouse = ({ isActive }) => {
  return (
    <img
      className="menu-items-aqua__icon"
      src={House}
      alt="aqua-icon"
      style={{
        filter: isActive
          ? 'invert(92%) sepia(11%) saturate(5161%) hue-rotate(314deg) brightness(112%) contrast(106%)'
          : 'invert(100%)',
      }}
    />
  );
};

export const AquaVilla = ({ isActive }) => {
  return (
    <img
      className="menu-items-aqua__icon"
      src={Villa}
      alt="aqua-icon"
      style={{
        filter: isActive
          ? 'invert(92%) sepia(11%) saturate(5161%) hue-rotate(314deg) brightness(112%) contrast(106%)'
          : 'invert(100%)',
      }}
    />
  );
};

export const ShareIcon = () => {
  return (
    <img
      src={Share}
      alt="aqua-icon"
      style={{ height: 20, filter: 'invert(100%)' }}
    />
  );
};

export const AquaRightArrow = () => {
  return (
    <img
      src={RightArrow}
      alt="aqua-icon"
      style={{
        height: isMobile ? 8 : 13,
        filter: 'invert(100%)',
      }}
    />
  );
};

export const AquaSceneMenuIcon = () => {
  return (
    <img
      src={SceneMenuIcon}
      alt="aqua-icon"
      style={{
        height: isMobile ? 8 : 30,
        filter:
          'invert(5%) sepia(87%) saturate(7291%) hue-rotate(245deg) brightness(78%) contrast(117%)',
      }}
    />
  );
};

export const AquaLargeMapfloorplanIcon = () => {
  return (
    <img
      src={LargeMapfloorplanIcon}
      alt="aqua-icon"
      style={{
        height: isMobile ? 8 : 30,
        filter:
          'invert(5%) sepia(87%) saturate(7291%) hue-rotate(245deg) brightness(78%) contrast(117%)',
      }}
    />
  );
};
