import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import configs from 'configs';
import { canFullscreen, toggleFullScreen } from 'utils';

import { useNavigatedRoutes } from 'common/hooks';
import GalleryMenu from 'components/GalleryMenu';
import SchoolLayoutMenu from 'components/SchoolLayoutMenu';
import {
  AudioIcon,
  AutoRotateIcon,
  BackToDefaultSceneButton,
  ContactFormIcon,
  FloorMapIcon,
  FullScreenIcon,
  HelpIcon,
  MapFloorPlanIcon,
  MapIcon,
  PinIcon,
} from 'components/icons';
import { AutoPlayIcon } from 'components/icons/AutoPlayIcon';
import PanoMenu from 'components/panoMenu';
import { LAYOUT_UI } from 'consts/ui.const';
import { setAutoPlay } from 'store/actions';
import { limitLetters } from 'utils/limitLetters';
import SchoolLayoutIcons from './SchoolLayoutIcons';
import { backToDefaultScene } from 'containers/world/utils';
import SimpleLayoutIcons from './SimpleLayoutIcons';

const MenuForDesktop = (props) => {
  const {
    menuList,
    isNavOpen,
    menuPosition,
    currentPano,
    subTitle,
    menuOpenScene,
    toggleRotate,
    toggleMenu,
    shouldRotate,
    featuredMedia,
    color,
    isShowOnMap,
    isShowAudioIcon,
    isShowMapFloorPlan,
    floorMaps,
    tour,
    showLanding,
    groups,
    onSelect,
    scenes,
    openMenu,
    setOpenMenu,
    isAquaStyle,
    imageSize,
    setImageSize,
  } = props;
  const [handleUpdateRoute] = useNavigatedRoutes();
  const [fullScreen, setFullScreen] = useState(false);

  const autoPlayMode = useSelector((state) => state.autoPlayMode);
  const [isAutoPlay, setIsAutoPlay] = useState(autoPlayMode);
  const [isActiveMap, setIsActiveMap] = useState(true);

  const [openDropDown, setOpenDropDown] = useState(false);
  const [mapShowed, setMapShowed] = useState();

  const isVerticalImage = useMemo(
    () => imageSize.height > imageSize.width,
    [imageSize]
  );

  useEffect(() => {
    setOpenDropDown(false);
  }, [currentPano]);

  const isMenuGallery = useSelector(
    ({ searchParams }) => searchParams['menu-ui'] === 'gallery'
  );

  const shouldShowMapIcon = useMemo(
    () =>
      tour.mapCoordinates.length > 0 &&
      !(tour.mapCoordinates[0] === 0 && tour.mapCoordinates[1] === 0),
    [tour]
  );

  const isSchoolLayout = useMemo(
    () => tour.menuStyle === LAYOUT_UI.SCHOOL,
    [tour]
  );

  const isBothersFurnitureLayout = useMemo(
    () => tour.menuStyle === LAYOUT_UI.BROTHERS_FURNITURE,
    [tour]
  );

  const isSimpleLayout = useMemo(
    () => tour.menuStyle === LAYOUT_UI.SIMPLE,
    [tour]
  );

  const toggleHelpButton = () => {
    showLanding();
  };

  const handleFullScreen = () => {
    toggleFullScreen();
    setFullScreen(!fullScreen);
  };

  const dispatch = useDispatch();

  const switchMode = useCallback(() => {
    setIsAutoPlay(!isAutoPlay);
    dispatch(setAutoPlay(!isAutoPlay));
  }, [dispatch, isAutoPlay]);

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  useEffect(() => {
    if (floorMaps.length > 0 && currentPano) {
      const getLinkedFloorPlanFromOtherScene = (floorMaps, pano) => {
        return floorMaps.find((item) =>
          item.objects.length
            ? item.objects.some((obj) => obj.id === pano._id)
            : item
        );
      };

      const linkedFloorPlan = getLinkedFloorPlanFromOtherScene(
        floorMaps,
        currentPano
      );
      if (linkedFloorPlan) {
        setMapShowed(linkedFloorPlan);
      } else {
        setMapShowed(floorMaps[0]);
      }
    }
  }, [currentPano, floorMaps]);

  const handleSelectMap = (item) => {
    setMapShowed(item);
  };

  return (
    <div className={`menuV2-desktop--container`}>
      {isMenuGallery && (
        <GalleryMenu
          scenes={scenes}
          desktop={true}
          groups={groups}
          onSelect={onSelect}
          activeColor={color}
          currentPano={currentPano}
          openMenu={openMenu}
          handleOpenMenu={handleOpenMenu}
        />
      )}

      {isSchoolLayout && (
        <SchoolLayoutMenu
          menuList={menuList}
          scenes={scenes}
          desktop={true}
          groups={groups}
          onSelect={onSelect}
          activeColor={color}
          currentPano={currentPano}
          openMenu={openMenu}
          handleOpenMenu={handleOpenMenu}
          isSchoolLayout={isSchoolLayout}
        />
      )}

      <div
        className={`menuV2-desktop--container_left ${
          isMenuGallery
            ? `container-width ${!openMenu && 'container-width-closed'}`
            : ''
        } ${isSimpleLayout ? 'hide-menu' : ''}`}
      >
        <div>
          {!isMenuGallery && !isSchoolLayout && (
            <div
              className="header"
              onClick={() => toggleMenu(tour.isPublic ? false : !isNavOpen)}
              style={{ cursor: ` ${tour.isPublic ? 'default' : 'pointer'}` }}
            >
              <div className="header_icon">
                <PinIcon color={color} />
              </div>
              <div className="header_titles">
                <div className="header_titles_title" style={{ color }}>
                  {currentPano ? limitLetters(currentPano.title, 100) : null}
                </div>
                <p className="header_titles_label">{subTitle || null}</p>
              </div>
            </div>
          )}
          <PanoMenu
            isOpen={isNavOpen}
            menu={menuList}
            location={menuPosition}
            onClose={() => toggleMenu(false)}
            onSelect={menuOpenScene}
            color={color}
          />
        </div>
      </div>
      <div className="menuV2-desktop--container_right">
        <div
          className={`icons_left ${
            isMenuGallery && !openMenu ? 'margin-left__0' : ''
          }`}
        >
          {shouldShowMapIcon && (
            <div
              className="icon"
              style={{
                display: isShowOnMap ? 'flex' : 'none',
              }}
              onClick={() => handleUpdateRoute(configs.mapRoute)}
            >
              <MapIcon />
            </div>
          )}
        </div>
        {isSchoolLayout && (
          <SchoolLayoutIcons
            currentPano={currentPano}
            isAutoPlay={isAutoPlay}
            color={color}
            toggleHelpButton={toggleHelpButton}
            featuredMedia={featuredMedia}
            isShowAudioIcon={isShowAudioIcon}
            toggleRotate={toggleRotate}
            shouldRotate={shouldRotate}
            canFullscreen={canFullscreen}
            menuPosition={menuPosition}
            handleFullScreen={handleFullScreen}
            fullScreen={fullScreen}
            isShowMapFloorPlan={isShowMapFloorPlan}
            setIsActiveMap={setIsActiveMap}
            isActiveMap={isActiveMap}
            mapShowed={mapShowed}
            setOpenDropDown={setOpenDropDown}
            openDropDown={openDropDown}
            handleSelectMap={handleSelectMap}
            scenes={scenes}
            onSelect={onSelect}
            switchMode={switchMode}
            handleUpdateRoute={handleUpdateRoute}
            isSchoolLayout={isSchoolLayout}
            isAquaStyle={isAquaStyle}
          />
        )}

        {isSimpleLayout && (
          <SimpleLayoutIcons
            currentPano={currentPano}
            isAutoPlay={isAutoPlay}
            color={color}
            toggleHelpButton={toggleHelpButton}
            featuredMedia={featuredMedia}
            isShowAudioIcon={isShowAudioIcon}
            toggleRotate={toggleRotate}
            shouldRotate={shouldRotate}
            canFullscreen={canFullscreen}
            menuPosition={menuPosition}
            handleFullScreen={handleFullScreen}
            fullScreen={fullScreen}
            isShowMapFloorPlan={isShowMapFloorPlan}
            setIsActiveMap={setIsActiveMap}
            isActiveMap={isActiveMap}
            mapShowed={mapShowed}
            setOpenDropDown={setOpenDropDown}
            openDropDown={openDropDown}
            handleSelectMap={handleSelectMap}
            scenes={scenes}
            onSelect={onSelect}
            switchMode={switchMode}
            handleUpdateRoute={handleUpdateRoute}
            isSchoolLayout={isSchoolLayout}
            isAquaStyle={isAquaStyle}
            floorMaps={floorMaps}
          />
        )}

        {!isSchoolLayout && !isSimpleLayout && (
          <div className="icons_right">
            {isBothersFurnitureLayout && (
              <div
                className={`icon`}
                onClick={() => backToDefaultScene(menuList, scenes, onSelect)}
              >
                <BackToDefaultSceneButton
                  menuList={menuList}
                  currentPano={currentPano}
                />
              </div>
            )}

            {shouldShowMapIcon && (
              <div
                className={`icon`}
                style={{
                  display: isShowOnMap ? 'flex' : 'none',
                }}
                onClick={() => handleUpdateRoute(configs.mapRoute)}
              >
                <MapIcon />
              </div>
            )}

            <div className={`icon`} onClick={() => switchMode()}>
              <AutoPlayIcon shouldRotate={isAutoPlay} activeColor={color} />
            </div>

            <div className={`icon`} onClick={toggleHelpButton}>
              <HelpIcon />
            </div>
            {featuredMedia.map && (
              <div
                className={`icon`}
                onClick={() => handleUpdateRoute(featuredMedia.map.id)}
              >
                <FloorMapIcon />
              </div>
            )}
            {featuredMedia.contact && (
              <div
                className={`icon`}
                onClick={() => handleUpdateRoute(featuredMedia.contact.id)}
              >
                <ContactFormIcon />
              </div>
            )}
            {isShowAudioIcon && (
              <AudioIcon activeColor={color} className={`icon`} />
            )}
            <div
              className={`icon`}
              onClick={() => {
                toggleRotate();
              }}
            >
              <AutoRotateIcon shouldRotate={shouldRotate} activeColor={color} />
            </div>
            {canFullscreen() && menuPosition !== 'bottom' && (
              <div className={`icon`} onClick={handleFullScreen}>
                <FullScreenIcon fullScreen={fullScreen} activeColor={color} />
              </div>
            )}
            {isShowMapFloorPlan && (
              <>
                <div
                  className={`icon position-relative`}
                  onClick={() => setIsActiveMap(!isActiveMap)}
                >
                  <MapFloorPlanIcon active={isActiveMap} />
                </div>
                <div
                  className={`mapfloorplan_container ${
                    isActiveMap
                      ? `open ${isVerticalImage ? 'open-vertical--image' : ''}`
                      : ''
                  }`}
                >
                  {mapShowed && (
                    <>
                      {floorMaps?.length > 1 && (
                        <div className="map_header">
                          <div
                            className="map_dropdown"
                            onClick={() => setOpenDropDown(!openDropDown)}
                          >
                            <span>
                              {limitLetters(mapShowed.image.name, 15)}
                            </span>
                            <i className="map_arrow down"></i>
                            {openDropDown && (
                              <div
                                className={`map_dropdown_options ${
                                  openDropDown ? 'show' : ''
                                }`}
                              >
                                <ul className="dropdown_items">
                                  {floorMaps?.map((item, index) => (
                                    <li
                                      onClick={() => handleSelectMap(item)}
                                      key={index}
                                    >
                                      {limitLetters(item.image.name, 10)}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="map_image">
                        <img
                          src={mapShowed.image.url}
                          alt={mapShowed.image.name}
                          onLoad={(e) =>
                            setImageSize({
                              width: e.target.naturalWidth,
                              height: e.target.naturalHeight,
                            })
                          }
                        />
                        {mapShowed.objects.map((viewpoint) => {
                          const currentPoint = viewpoint.id === currentPano._id;
                          const [left, top] = viewpoint.coordinates;
                          const leftInPercent = left * 100;
                          const topInPercent = top * 100;
                          const currentScene =
                            scenes.find((s) => s._id === viewpoint.id) || {};

                          return (
                            <div
                              key={viewpoint.id}
                              className={`viewpoint-container ${
                                currentPoint ? 'current-viewpoint' : 'viewpoint'
                              } `}
                              style={{
                                left: `${leftInPercent}%`,
                                top: `${topInPercent}%`,
                              }}
                              onClick={() =>
                                onSelect(currentScene.groupId, currentScene.id)
                              }
                            >
                              <p className="scene-title">
                                {currentScene.title}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuForDesktop;
