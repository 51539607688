import React, { useState, useRef, useCallback, useMemo } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { isMobile } from 'react-device-detect';
import CalloutLine from './calloutLine';
import { Html } from './Html';
import { Html as ThreeHtml } from '@react-three/drei';
import CanvasCircle from './canvasCircle';
import Canvas from './canvas';
import { CANVAS_W, CANVAS_H } from './utils';
import HotspotContainer from './hotspotContainer';
import HotspotIcon from '../icons';
import MEDIA_TYPES from 'consts/mediaType.const';
import {
  get2DScreenPosition,
  distanceBetween2dCoordinates,
} from 'utils/positionHelper';
import { getCenterScreen } from 'containers/world/utils';
import { trackTourContentVisible } from 'gaTracking';
import { useSelector } from 'react-redux';
import { HOTSPOT_UI, LAYOUT_UI } from 'consts/ui.const';
import { checkIsShowHotspotMap } from 'utils';
import CanvasPulsePoint from './canvasPulsePoint';
import AvoriazHotspot from 'components/AvoriazLayout/AvoriazHotspot';
import ClearSpaceHotspot from 'containers/world/sceneController/ClearSpaceHotspot/ClearSpaceHotspot';

const MAX_VISIBLE_DISTANCE = 150;
const HOVERzINDEX = '100';
const zIndexRange1 = [50, 0];
const zIndexRangeHover = [100, 100];

const styles = {
  width: `${CANVAS_W}px`,
  height: `${CANVAS_H}px`,
  pointerEvents: 'none',
};

const LineSingle = (props) => {
  const { media, hotspot } = props;
  const { camera } = useThree();
  const canvasRef = useRef();
  const hotspotRef = useRef();
  const elRef = useRef();
  const [zIndex, setZIndex] = useState('0');
  const [zIndexRange, setZIndexRange] = useState(zIndexRange1);
  const [hovering, setHovering] = useState(false);
  const trackingRef = useRef(false);
  const params = useSelector(({ searchParams }) => searchParams);
  const hotspotStyle = useSelector(({ tour }) => tour.hotspotStyle);
  const isAquaStyle = useSelector(({ tour }) => tour.menuStyle === 'aqua');
  const isAvoriazStyle = useSelector(
    ({ tour }) => tour.menuStyle === LAYOUT_UI.AVORIAZ
  );
  const isClearSpaceStyle = useSelector(
    ({ tour }) => tour.menuStyle === LAYOUT_UI.CLEAR_SPACE
  );

  const isCalloutStyle = useSelector(
    ({ searchParams }) => searchParams['hotspot-ui'] === HOTSPOT_UI.CALLOUT
  );
  const isTag = useMemo(() => {
    return media?.type === MEDIA_TYPES.INFO_TAG;
  }, [media]);

  const hoverToggled = (isHover) => {
    const parentNode = elRef.current && elRef.current.parentNode;
    setHovering(Boolean(isHover));
    if (isHover) {
      setZIndexRange(zIndexRangeHover);
      props.setHotspotHovering(true);
      if (parentNode) {
        setZIndex(parentNode.style.zIndex);
        parentNode.style.zIndex = HOVERzINDEX;
      }
    } else {
      props.setHotspotHovering(false);
      setTimeout(() => {
        if (elRef && elRef.current) {
          setZIndexRange(zIndexRange1);
          if (parentNode) {
            parentNode.style.zIndex = zIndex;
          }
        }
      }, 0);
    }
  };

  useFrame(() => {
    if (isMobile) {
      const [x, y, z] = props.position;
      const xyHotSpotPos = get2DScreenPosition(
        new THREE.Vector3(x, y, z),
        camera,
        window.innerWidth,
        window.innerHeight
      );
      const screenCenter = getCenterScreen();
      const distance = distanceBetween2dCoordinates(xyHotSpotPos, screenCenter);
      if (distance <= MAX_VISIBLE_DISTANCE) {
        setHovering(true);
      } else {
        setHovering(false);
      }
    }
  });

  const onHotspotVisible = useCallback(() => {
    if (props.media && !trackingRef.current) {
      trackTourContentVisible({
        media_id: props.media._id,
        tour_id: props.tour.id,
        scene_id: props.currentPano._id,
      });

      trackingRef.current = true;
    }
  }, [props.media, props.currentPano._id, props.tour.id]);

  const isShowMapFloorPlanButton = useMemo(() => {
    return checkIsShowHotspotMap(hotspot);
  }, [hotspot]);

  const configsData = useMemo(() => {
    if (!props?.hotspot?.configs) return null;
    return JSON.parse(props?.hotspot?.configs);
  }, [props?.hotspot?.configs]);

  if (isClearSpaceStyle)
    return (
      <ThreeHtml ref={elRef} position={props.position}>
        <ClearSpaceHotspot
          hotspot={props.hotspot}
          groups={props.groups}
          onClick={props.onClick}
        />
      </ThreeHtml>
    );

  if (isAvoriazStyle) {
    return (
      <ThreeHtml ref={elRef} position={props.position}>
        <AvoriazHotspot
          hotspot={props.hotspot}
          groups={props.groups}
          onClick={props.onClick}
        />
      </ThreeHtml>
    );
  }

  if (
    hotspotStyle === HOTSPOT_UI.CALLOUT ||
    isTag ||
    isCalloutStyle ||
    isAquaStyle
  ) {
    return (
      <CalloutLine
        ref={elRef}
        hotspotRef={hotspotRef}
        canvasRef={canvasRef}
        position={props.position}
        center={true}
        zIndexRange={zIndexRange}
        style={styles}
        onVisible={onHotspotVisible}
        forceUp={props.forceUp}
        isAquaStyle={isAquaStyle}
        infoTagConfig={isTag && props.configsData}
      >
        <>
          {isAquaStyle ? (
            <>
              <Canvas ref={canvasRef} />
              {configsData &&
              configsData.baseIconType === 'circle_animation' ? (
                <CanvasPulsePoint />
              ) : (
                <CanvasCircle />
              )}
            </>
          ) : (
            <>
              <CanvasCircle />
              <Canvas ref={canvasRef} />
            </>
          )}

          <HotspotContainer ref={hotspotRef}>
            <HotspotIcon
              type={props.type}
              hotspot={props.hotspot}
              currentSceneCoords={props.currentSceneCoords}
              scene={props.scene}
              media={props.media}
              tour={props.tour}
              onClick={props.onClick}
              isHover={hovering}
              toggleHover={(val) => hoverToggled(val)}
              hoverIn={() => hoverToggled(true)}
              hoverOut={() => hoverToggled(false)}
              visited={props.visited}
              color={props.color || ''}
              scope={props.scope}
              hotspotStyle={hotspotStyle}
              isShowMapFloorPlanButton={isShowMapFloorPlanButton}
            />
          </HotspotContainer>
        </>
      </CalloutLine>
    );
  }

  return (
    <Html
      ref={elRef}
      position={props.position}
      center={true}
      zIndexRange={zIndexRange}
      style={styles}
      onVisible={onHotspotVisible}
    >
      <HotspotContainer>
        <HotspotIcon
          type={props.type}
          hotspot={props.hotspot}
          currentSceneCoords={props.currentSceneCoords}
          scene={props.scene}
          media={props.media}
          tour={props.tour}
          onClick={props.onClick}
          isHover={hovering}
          toggleHover={(val) => hoverToggled(val)}
          hoverIn={() => hoverToggled(true)}
          hoverOut={() => hoverToggled(false)}
          visited={props.visited}
          color={props.color || ''}
          scope={props.scope}
          params={params}
          hotspotStyle={hotspotStyle}
          isShowMapFloorPlanButton={isShowMapFloorPlanButton}
        />
      </HotspotContainer>
    </Html>
  );
};

export default React.memo(LineSingle);
