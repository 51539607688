import { ReactComponent as CaretDownIcon } from 'assets/icons/fa-caret-down.svg';
import { useState } from 'react';

const ResortMenuItem = ({ menu, selectSceneById, handleToggleMenuMobile }) => {
  const [toggleSubMenuMobile, setToggleSubMenuMobile] = useState(false);

  const handleToggleSubMenuMobile = () => {
    if (menu.groupId && menu.id) {
      selectSceneById(menu.groupId, menu.id);
      handleToggleMenuMobile()
    } else {
      setToggleSubMenuMobile((value) => !value);
    }
  };

  const goToScene = (groupId, sceneId) => {
    selectSceneById(groupId, sceneId);
    handleToggleMenuMobile();
  }

  return (
    <div
      className={`menu-list-item${
        toggleSubMenuMobile ? ' open-on-mobile' : ''
      }`}
    >
      <h1 className="menu-list-item-title" onClick={handleToggleSubMenuMobile}>
        {menu.name}
        {!!menu.scenes?.length && <CaretDownIcon className="icon" />}
      </h1>
      <div className="submenu-list">
        {menu.scenes?.map((scene, sIndex) => (
          <div className="submenu-list-item" key={sIndex}>
            <h2
              className="submenu-list-item-title"
              onClick={() => goToScene(scene.groupId, scene.id)}
            >
              {scene.title}
            </h2>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResortMenuItem;
