import { useSelector } from 'react-redux';
import MobiBackground from './MobiBackground';
import vizionLogo from 'assets/images/logo/vizion_logo.png';
import MobiMenu from './MobiMenu';
import MobiController from './MobiController';

const MobiHomeLayout = ({
  currentPano,
  selectSceneById,
  isShowMapFloorPlan,
  floorMaps,
  showLanding,
}) => {
  const tour = useSelector((state) => state.tour);
  const tourLogo = tour.logoURL || vizionLogo;

  return (
    <div className="mobihome-container">
      <MobiBackground />
      <MobiController
        currentPano={currentPano}
        isShowMapFloorPlan={isShowMapFloorPlan}
        floorMaps={floorMaps}
        selectSceneById={selectSceneById}
        showLanding={showLanding}
      />
      <MobiMenu
        tourLogo={tourLogo}
        currentPano={currentPano}
        selectSceneById={selectSceneById}
      />
    </div>
  );
};

export default MobiHomeLayout;
