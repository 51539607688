import { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import NavInfo from './NavInfo';
import NavMenu from './NavMenu';
import TourMenu from './TourMenu';
import { useMediaQuery } from 'react-responsive';
import ControlMenu from './ControlMenu';
import AquaModal from 'components/AquaModal';

const AquaLayout = (props) => {
  const { json, currentPano, selectSceneById, isShowMapFloorPlan, floorMaps } =
    props;
  const menuList = json.menu;
  const isMobile = useMediaQuery({ query: `(max-width: 576px)` });
  const [openMenu, setOpenMenu] = useState(!isMobile);
  const [isOpenAquaModal, setIsOpenAquaModal] = useState(false);
  const [aquaModalData, setAquaModalData] = useState({});

  const handleOpenMenu = () => {
    setOpenMenu((state) => !state);
  };

  const handleOpenAquaModal = (modalContent) => {
    setIsOpenAquaModal(true);
    setAquaModalData(modalContent);
  };

  const currentMenu = useMemo(() => {
    if (currentPano) {
      return menuList.find((item) =>
        item.scenes.some((sce) => sce._id === currentPano._id)
      );
    }
    return null;
  }, [currentPano, menuList]);

  return (
    <div className="aqua-layout-container">
      <TourMenu
        openMenu={openMenu}
        isMobile={isMobile}
        handleOpenMenu={handleOpenMenu}
        selectSceneById={selectSceneById}
      />

      <NavMenu
        selectSceneById={selectSceneById}
        handleOpenAquaModal={handleOpenAquaModal}
      />

      {currentMenu?.name && currentPano?.title && (
        <NavInfo menuName={currentMenu?.name} sceneName={currentPano?.title} />
      )}

      <ControlMenu
        isShowMapFloorPlan={isShowMapFloorPlan}
        floorMaps={floorMaps}
        currentPano={currentPano}
        selectSceneById={selectSceneById}
        handleOpenAquaModal={handleOpenAquaModal}
      />

      {isOpenAquaModal && (
        <AquaModal
          handleClose={() => setIsOpenAquaModal(false)}
          contentData={aquaModalData}
          selectSceneById={selectSceneById}
        />
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  json: store.json,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AquaLayout);
