import * as APP_ACTION from './actionTypes';
import initialState from './state';

function appReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case APP_ACTION.INITIATED:
      return {
        ...state,
        ready: true,
      };
    case APP_ACTION.SET_TOUR_ERROR:
      return {
        ...state,
        error: payload,
      };
    case APP_ACTION.UPDATE_DIMENTIONS:
      return {
        ...state,
        dimentions: { ...payload },
      };
    case APP_ACTION.TOGGLE_PANO_MODE:
      return {
        ...state,
        panoMode: payload,
      };
    case APP_ACTION.TOGGLE_MENU:
      return {
        ...state,
        menu: {
          ...state.menu,
          isOpen: payload,
        },
      };

    case APP_ACTION.SET_MENU_POSITION:
      return {
        ...state,
        menu: {
          ...state.menu,
          position: payload,
        },
      };
    case APP_ACTION.SET_TOUR_DATA:
      const enableBrandingObject = payload.subscriptionPlan.scopes.find(
        (scope) => scope.key === 'ENABLE_BRANDING'
      );
      const enableBranding = Boolean(enableBrandingObject.value);

      return {
        ...state,
        tour: { ...payload },
        subscriptionPlan: {
          enableBranding: enableBranding,
        },
      };
    case APP_ACTION.SET_PANO_DATA:
      const { scenes, groups } = payload;
      const logoURL = payload.tour.logoURL || '';
      const hasLogo = logoURL.trim().length > 0;
      return {
        ...state,
        json: { ...payload },
        groups: [...groups],
        scenes: [...scenes],
        logoURL: logoURL,
        hasLogo: hasLogo,
      };
    case APP_ACTION.GO_TO_PANO:
      const scene = state.scenes.find(
        (s) => s.id === payload.id && s.groupId === payload.groupId
      );
      return {
        ...state,
        currentScene: { ...scene },
        currentHotspot: null,
        menu: {
          ...state.menu,
          isOpen: false,
          social: false,
          isoMetricMap: false,
        },
        hotspotHistory: [...state.hotspotHistory, scene._id],
      };

    case APP_ACTION.CLOSE_LANDING:
      return {
        ...state,
        showLanding: false,
        blur: false,
      };
    case APP_ACTION.SET_AERIAL_MAP_DATA:
      return {
        ...state,
        aerialMapData: payload,
        aerialDataReady: true,
      };
    case APP_ACTION.TOGGLE_BLUR:
      return {
        ...state,
        blur: payload !== undefined ? payload : !state.blur,
      };
    case APP_ACTION.SET_AUDIO_AVAILABLE:
      return {
        ...state,
        audio: {
          ...state.audio,
          available: payload,
        },
      };
    case APP_ACTION.SET_AUDIO_AUTOPLAY:
      return {
        ...state,
        audio: {
          ...state.audio,
          autoPlay: true,
        },
      };
    case APP_ACTION.TOGGLE_AUDIO:
      return {
        ...state,
        audio: {
          ...state.audio,
          enabled: payload,
        },
      };
    // Control Mode
    case APP_ACTION.SET_GYRO:
      return { ...state, gyro: payload };
    case APP_ACTION.SET_CONTROL_MODE:
      return { ...state, controlMode: payload };
    case APP_ACTION.SET_CENTER_LOOKAT:
      return { ...state, centerLookAt: payload };

    // Hotspots
    case APP_ACTION.ADD_HOTSPOT_HISTORY:
      return { ...state, hotspotHistory: [...state.hotspotHistory, payload] };

    // Landing
    case APP_ACTION.TOGGLE_LANDING:
      return { ...state, isLandingOpen: payload };

    // View Mode
    case APP_ACTION.SET_VIEW_MODE:
      return {
        ...state,
        viewMode: payload,
      };

    case APP_ACTION.SET_AUTO_PLAY:
      return {
        ...state,
        autoPlayMode: payload,
      };

    case APP_ACTION.UPDATE_PREV_ROTATION:
      return { ...state, prevRotation: payload };

    case APP_ACTION.SET_AUTO_ROTATE:
      return { ...state, autoRotate: payload };

    case APP_ACTION.SET_SEARCH_PARAMS:
      return { ...state, searchParams: { ...state.searchParams, ...payload } };

    case APP_ACTION.SET_OPEN_POPUP:
      return { ...state, isOpenPopUp: payload };

    case APP_ACTION.SET_IS_PANNING:
      return { ...state, isPanning: payload };

    case APP_ACTION.SET_LIMIT_ORBIT:
      return { ...state, limitOrbit: payload };

    case APP_ACTION.UPDATE_WORLD_PARAMS:
      return {
        ...state,
        worldParams: {
          ...state.worldParams,
          ...payload,
        },
      };

    default:
      return state;
  }
}

export default appReducer;
