export function hasFileExtension(url) {
  const extensionRegex =
    /\.(?:jpg|jpeg|png|gif|pdf|doc|docx|xls|xlsx|ppt|pptx|mp3|mp4|wav|txt|zip|rar|csv|css|js|json)$/i;
  return extensionRegex.test(url);
}

const OLD_RESOURCE_URL = process.env.REACT_APP_OLD_RESOURCE_URL;
const NEW_RESOURCE_URL = process.env.REACT_APP_NEW_RESOURCE_URL;

const convertToNewResourceUrl = (url) => {
  if (url.indexOf(OLD_RESOURCE_URL) === 0) {
    let newUrl = url.replace(OLD_RESOURCE_URL, NEW_RESOURCE_URL);

    if (!hasFileExtension(newUrl)) {
      newUrl += '.jpeg';
    }
    return newUrl;
  }
  return url;
};

const checkSingleItem = (item) => {
  if (
    typeof item === 'number' ||
    typeof item === 'boolean' ||
    item === '' ||
    item === null
  ) {
    // do nothing
    return item;
  } else if (typeof item === 'string') {
    return convertToNewResourceUrl(item);
  } else if (item.constructor === Array) {
    return convertArrayKeys(item);
  } else if (typeof item === 'object') {
    return convertObjectKeys(item);
  }

  return item;
};

const convertArrayKeys = (arrData) => {
  const newArr = [];
  arrData.forEach((item) => {
    newArr.push(checkSingleItem(item));
  });

  return newArr;
};

export const convertObjectKeys = (objData) => {
  const newObj = {};
  Object.keys(objData).forEach((key) => {
    newObj[key] = checkSingleItem(objData[key]);
  });

  return newObj;
};
