import { ReactComponent as MenuBarIcon } from 'assets/icons/fa-bar.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/fa-xmark.svg';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import './mobiMenu.scss';

const MobiMenu = ({ tourLogo, currentPano, selectSceneById }) => {
  const json = useSelector((state) => state.json);

  const [toggleMenu, setToggleMenu] = useState(false);

  const scenesList = json.scenes;

  const handleToggleMenu = () => {
    setToggleMenu((value) => !value);
  };

  const handleSelectScene = (groupId, sceneId) => {
    selectSceneById(groupId, sceneId);
    handleToggleMenu();
  };

  return (
    <div className="mobi-menu-container">
      <div
        className={`bg-close${toggleMenu ? ' open' : ''}`}
        onClick={handleToggleMenu}
      >
        {/* click outside to close */}
      </div>

      <div className={`mobi-logo${toggleMenu ? ' open' : ''}`}>
        <img src={tourLogo} alt="Logo" />
      </div>
      <div className={`menu-btn${toggleMenu ? ' open' : ''}`}>
        <div className="menu-btn-icon" onClick={handleToggleMenu}>
          <MenuBarIcon className="menu-icon icon" />
          <CloseIcon className="close-icon icon" />
        </div>
        {currentPano?.title && (
          <div className="scene-name">{currentPano?.title}</div>
        )}
      </div>

      <div className={`menu-list-container${toggleMenu ? ' open' : ''}`}>
        <div className="menu-list-logo">
          <img src={tourLogo} alt="Logo" />
        </div>
        <div className="menu-list">
          {scenesList.map((scene, index) => (
            <div
              className={`menu-list-item${
                currentPano?.groupId === scene.groupId &&
                currentPano?.id === scene.id
                  ? ' active'
                  : ''
              }`}
              key={index}
              onClick={() => handleSelectScene(scene.groupId, scene.id)}
            >
              <img
                src={scene.previewImgUrl}
                alt={scene.title}
                className="menu-list-item-img"
              />
              <div className="menu-list-item-title">{scene.title}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobiMenu;
