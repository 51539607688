import React from 'react';
import { useTranslation } from 'react-i18next';

import DropdownLanguage from './DropupLanguage';

export default function LanguageSelect() {
  const { t } = useTranslation();
  return (
    <div className="language-select">
      <div className="language-select__icon">
        <DropdownLanguage />
      </div>
      <div className="language-select__title">
        {t('choose_your_preferred_language')}
      </div>
    </div>
  );
}
