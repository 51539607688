import HoveringPlate from 'components/HoveringPlate';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';

const ViewPointMapPlan = (props) => {
  const { viewpoint, currentPano, selectSceneById, json } = props;
  const sceneList = json.scenes;

  const currentPoint = viewpoint.id === currentPano._id;
  const currentScene = sceneList.find((s) => s._id === viewpoint.id) || {};

  const pos = useMemo(() => {
    const [left, top] = viewpoint.coordinates;

    return {
      left: left * 100,
      top: top * 100,
    };
  }, [viewpoint.coordinates]);

  return (
    <div
      className={`viewpoint-container  ${
        currentPoint
          ? 'current-viewpoint aquaAnimation'
          : `viewpoint aquaViewPoint`
      } `}
      style={{
        left: `${pos.left}%`,
        top: `${pos.top}%`,
      }}
      onClick={() => selectSceneById(currentScene.groupId, currentScene.id)}
    >
      <HoveringPlate textHover={currentScene.title}>
        <div className="hover-point"></div>
      </HoveringPlate>
    </div>
  );
};

const mapStateToProps = ({ json }) => ({
  json,
});

export default connect(mapStateToProps)(ViewPointMapPlan);
