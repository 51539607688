import './avoriazMenuButton.scss';
import './avoriazMenuButtonMobile.scss';

const AvoriazMenuButton = ({ toggleMenu, handleToggleMenu }) => {
  return (
    <div
      className={`avoriaz-menu-button-container${toggleMenu ? ' open' : ''}`}
      onClick={() => handleToggleMenu(!toggleMenu)}
    >
      <div className="nav-menu">
        <div className="nav-menu-button">
          <div className="nav-menu-button-icon nav-menu-button-icon-1"></div>
          <div className="nav-menu-button-icon nav-menu-button-icon-2"></div>
          <div className="nav-menu-button-icon nav-menu-button-icon-3"></div>
        </div>
        <div className="close-menu-button">
          <div className="close-menu-button-icon close-menu-button-icon-1"></div>
          <div className="close-menu-button-icon close-menu-button-icon-2"></div>
        </div>
      </div>
    </div>
  );
};

export default AvoriazMenuButton;
