import { useEffect, useMemo, useRef } from 'react';
import { Vector2 } from 'three';
import { extend, useFrame, useThree } from '@react-three/fiber';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { gsap } from 'gsap';

import { useBlur } from 'common/hooks';

import { TriangleBlurShader } from './shaders/triangleShader';

extend({ EffectComposer, ShaderPass, RenderPass });

const RADIUS = 30;
const obj = { rad: RADIUS };

function TriangleBlurEffect({ enteredPasscode }) {
  const blur = useBlur();
  const isOn = useRef(false);
  const { gl, scene, camera, size } = useThree();
  const shouldBlur = useMemo(() => !!(blur || !enteredPasscode), [blur, enteredPasscode]);

  const final = useMemo(() => {
    const renderScene = new RenderPass(scene, camera);
    const finalComposer = new EffectComposer(gl);
    const fstPass = new ShaderPass(TriangleBlurShader, 'texture1');
    fstPass.uniforms.delta.value = new Vector2(0, RADIUS / size.height);
    const sndPass = new ShaderPass(TriangleBlurShader, 'texture1');
    sndPass.uniforms.delta.value = new Vector2(RADIUS / size.width, 0);
    finalComposer.addPass(renderScene);
    finalComposer.addPass(fstPass);
    finalComposer.addPass(sndPass);
    return finalComposer;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    final.setSize(size.width, size.height);
  }, [final, size]);

  useEffect(() => {
    isOn.current = true;
    const anim = gsap.to(obj, {
      rad: shouldBlur ? RADIUS : 0,
      duration: 1.5,
      onUpdate: () => {
        if (final.passes[1] instanceof ShaderPass) {
          final.passes[1].uniforms.delta.value.y = obj.rad / size.height;
        }
        if (final.passes[2] instanceof ShaderPass) {
          final.passes[2].uniforms.delta.value.x = obj.rad / size.width;
        }
      },
      onComplete: () => {
        isOn.current = false;
      },
    });

    return () => anim && anim.pause();
    // eslint-disable-next-line
  }, [final, shouldBlur, isOn]);

  useFrame(() => {
    if (isOn.current || shouldBlur) {
      final.render();
    }
  }, 1);

  return null;
}

export default TriangleBlurEffect;
