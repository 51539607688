import React from 'react';
import './menuBarIcon.scss';

const MenuBarIcon = ({ openMenu, handleOpenMenu }) => {
  return (
    <div className="menu-bar-icon-container" onClick={handleOpenMenu}>
      <label className="menu-button-label">
        <div className={`menu-button ${openMenu ? 'menu-open' : ''}`}>
          <div className="menu-bar"></div>
          <div className="menu-bar"></div>
          <div className="menu-bar"></div>
        </div>
      </label>
    </div>
  );
};

export default MenuBarIcon;
