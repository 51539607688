import React from 'react';

export const GlobalIcon = () => {
  return (
    <svg
      height="36px"
      width="36px"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48.669 48.669"
    >
      <g>
        <path
          style={{ fill: '#010002' }}
          d="M24.335,0C10.917,0,0,10.915,0,24.333c0,13.378,10.853,24.268,24.217,24.332
		c0.005,0,0.009,0.003,0.014,0.003c0.004,0,0.008-0.002,0.013-0.002c0.031,0,0.061,0.002,0.091,0.002
		c13.418,0,24.334-10.917,24.334-24.334C48.67,10.916,37.753,0,24.335,0z M22.703,1.314c-1.97,1.958-5.879,6.229-8.603,11.678
		c-5.335-0.644-7.644-1.627-8.503-2.117C9.493,5.468,15.666,1.809,22.703,1.314z M4.895,11.909c1.014,0.6,3.407,1.604,8.636,2.272
		c-1.325,2.932-2.255,6.163-2.36,9.527H1.266C1.382,19.374,2.697,15.334,4.895,11.909z M1.266,24.959h9.904
		c0.104,3.332,1.018,6.531,2.322,9.44c-5.275,0.677-7.655,1.698-8.641,2.292C2.68,33.28,1.381,29.266,1.266,24.959z M5.547,37.722
		c0.809-0.476,3.084-1.481,8.506-2.136c2.726,5.493,6.668,9.799,8.649,11.769C15.636,46.857,9.44,43.168,5.547,37.722z
		 M23.71,46.581c-1.781-1.777-5.582-5.914-8.286-11.148c2.255-0.225,4.991-0.388,8.286-0.442V46.581z M23.71,33.742
		c-3.55,0.059-6.474,0.243-8.869,0.499c-1.343-2.872-2.296-6.024-2.407-9.281H23.71V33.742z M23.71,23.709H12.434
		c0.112-3.289,1.085-6.473,2.45-9.368c2.387,0.253,5.297,0.435,8.826,0.494C23.71,14.835,23.71,23.709,23.71,23.709z M23.71,13.584
		c-3.27-0.055-5.99-0.216-8.236-0.439C18.175,7.958,21.94,3.857,23.71,2.09V13.584z M47.404,23.709H37.396
		c-0.103-3.295-0.999-6.461-2.281-9.344c5.188-0.624,7.639-1.704,8.71-2.378C45.992,15.396,47.288,19.406,47.404,23.709z
		 M43.127,10.951c-0.894,0.553-3.24,1.622-8.568,2.221c-2.731-5.544-6.715-9.89-8.704-11.866
		C32.97,1.771,39.213,5.473,43.127,10.951z M24.96,2.195c1.856,1.867,5.58,5.964,8.227,11.116c-2.248,0.198-4.966,0.312-8.227,0.288
		V2.195z M24.96,14.849c0.274,0.002,0.554,0.005,0.821,0.005c3.156,0,5.789-0.133,7.994-0.345c1.323,2.849,2.26,5.977,2.37,9.2
		H24.96V14.849z M24.96,24.959h11.185c-0.109,3.188-1.033,6.289-2.34,9.119c-2.396-0.233-5.313-0.37-8.844-0.351v-8.768H24.96z
		 M24.96,34.976c0.258-0.002,0.52-0.005,0.772-0.005c2.94,0,5.409,0.117,7.487,0.302c-2.648,5.182-6.396,9.315-8.259,11.197V34.976z
		 M25.855,47.362c1.999-1.986,6.011-6.366,8.743-11.95c5.392,0.612,7.72,1.703,8.574,2.24
		C39.262,43.167,32.999,46.896,25.855,47.362z M43.866,36.618c-1.045-0.666-3.48-1.756-8.718-2.392c1.264-2.862,2.146-6,2.248-9.267
		h10.008C47.289,29.235,46.009,33.222,43.866,36.618z"
        />
      </g>
    </svg>
  );
};
