import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const ViewPointMapPlan = (props) => {
  const { viewpoint, currentPano, selectSceneById } = props;
  const json = useSelector((state) => state.json);
  const sceneList = json.scenes;

  const currentPoint = viewpoint.id === currentPano._id;
  const currentScene = sceneList.find((s) => s._id === viewpoint.id) || {};

  const pos = useMemo(() => {
    const [left, top] = viewpoint.coordinates;

    return {
      left: left * 100,
      top: top * 100,
    };
  }, [viewpoint.coordinates]);

  return (
    <div
      className={`viewpoint-container ${
        currentPoint ? 'current-viewpoint' : `viewpoint`
      } `}
      style={{
        left: `${pos.left}%`,
        top: `${pos.top}%`,
      }}
      onClick={() => selectSceneById(currentScene.groupId, currentScene.id)}
    >
      <div className="viewpoint-title-container">
        <div className="viewpoint-title">{currentScene.title}</div>
      </div>
    </div>
  );
};

export default ViewPointMapPlan;
