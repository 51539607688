import { AQUA_MODAL_TYPE } from 'consts/aquaLayout';
import './AquaModal.scss';
import HelpImg from 'assets/images/help_screen_fg.png';
import FloorPlan from './FloorPlan';

export default function AquaModal({
  contentData,
  handleClose,
  selectSceneById,
}) {
  return (
    <div className="aqua-modal">
      <div className="aqua-modal-container">
        {/* TODO: render youtube link */}
        {contentData?.type === AQUA_MODAL_TYPE.MAP && (
          <>
            <div className="spinner"></div>
            <div className="aqua-modal-iframe__wrapper">
              <iframe
                title="gg-map"
                className="map"
                src={contentData.url}
                referrerPolicy="no-referrer-when-cross-origin"
              />
            </div>
          </>
        )}

        {contentData?.type === AQUA_MODAL_TYPE.HELP && (
          <div className="help-container">
            <img src={HelpImg} alt="help-img" />
          </div>
        )}

        {contentData?.type === AQUA_MODAL_TYPE.FLOOR_PLAN &&
          contentData?.mediaHotspot && (
            <FloorPlan
              mediaHotspot={contentData.mediaHotspot}
              selectSceneById={selectSceneById}
              handleClose={handleClose}
            />
          )}

        <div
          className="aqua-modal-close"
          onClick={() => {
            handleClose();
          }}
        >
          <button className="aqua-modal-close__btn">&times;</button>
        </div>
      </div>
    </div>
  );
}
