import ScenesDragged from 'containers/world/mediaPopup/popup.mapFloorPlan/scenesDragged';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './FloorPlan.scss';

const FloorPlan = ({ mediaHotspot, selectSceneById, handleClose }) => {
  const { json, hotspotHistory } = useSelector((state) => ({
    json: state.json,
    hotspotHistory: state.hotspotHistory,
  }));
  const [currentFloorPlan, setCurrentFloorPlan] = useState(
    mediaHotspot.mapFloorPlan[0]
  );

  if (!currentFloorPlan?.image) return null;

  const imageData = currentFloorPlan.image;
  const objectsData = currentFloorPlan.objects;

  const goToScene = (groupId, sceneId) => {
    selectSceneById(groupId, sceneId);
    handleClose();
  }

  return (
    <div className="floor-plan-container">
      <div className="img-container">
        <img src={imageData.url} alt={imageData.name} />
        {objectsData.map((item) => (
          <ScenesDragged
            key={item.id}
            item={item}
            imgRoot={imageData}
            scenes={json.scenes}
            tour={json.tour}
            hotspotHistory={hotspotHistory}
            selectSceneById={goToScene}
          />
        ))}
      </div>
      {mediaHotspot.mapFloorPlan?.length > 1 && (
        <div className="floor-plan-list">
          {mediaHotspot.mapFloorPlan.map((floorPlan, index) => (
            <button
              className={`btn-floor-plan ${
                imageData.name === floorPlan.image.name &&
                imageData.url === floorPlan.image.url
                  ? 'active'
                  : ''
              }`}
              key={index}
              onClick={() => setCurrentFloorPlan(floorPlan)}
            >
              {floorPlan.image.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default FloorPlan;
