import { ReactComponent as ArrowRightIcon } from 'assets/icons/fa-arrow-right.svg';
import { AVORIAZ_ANIMATION_DURATION } from 'consts/avoriazLayout';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './avoriazMenu.scss';
import './avoriazMenuMobile.scss';
import AvoriazMenuButton from './AvoriazMenuButton';
import ExternalLink from 'components/ExternalLink';

const AvoriazMenu = (props) => {
  const { json, currentScene, selectSceneById } = props;
  const menuList = json.menu;

  const [currentMenu, setCurrentMenu] = useState();
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleMenuMobile, setToggleMenuMobile] = useState(false);
  const [triggerAnimationMenu, setTriggerAnimationMenu] = useState(false);

  const handleToggleMenu = (menuStatus) => {
    if (menuStatus) {
      setTimeout(() => {
        setTriggerAnimationMenu(true);
      }, AVORIAZ_ANIMATION_DURATION); // wait for animation duration to finish
      setToggleMenu(true);
    } else {
      setToggleMenu(false);
      setTriggerAnimationMenu(false);
    }
  };

  const onChangeScene = (groupId, sceneId) => {
    selectSceneById(groupId, sceneId);
    handleToggleMenu(false);
  };

  const onChangeMenu = (menuData) => {
    setCurrentMenu(null); // reset slide-in animation
    setTimeout(() => {
      setCurrentMenu(menuData);
      setToggleMenuMobile(false);
    }, 0); // wait for next state updated
  };

  const backToMenuList = () => {
    setToggleMenuMobile(true);
  };

  useEffect(() => {
    let currentMenuByScene = null;
    menuList.forEach((menuItem) => {
      const foundScene = menuItem.scenes?.find(
        (scene) =>
          scene.id === currentScene.id && scene.groupId === currentScene.groupId
      );
      if (foundScene) currentMenuByScene = menuItem;
    });
    setCurrentMenu(currentMenuByScene);
    // eslint-disable-next-line
  }, [currentScene]);

  return (
    <>
      <AvoriazMenuButton
        toggleMenu={toggleMenu}
        handleToggleMenu={handleToggleMenu}
      />
      <div
        className={`avoriaz-menu-container${!toggleMenu ? ' hidden' : ''}${
          toggleMenuMobile ? ' mobile-show-menu' : ''
        }`}
      >
        <div className="nav-overview">
          <ul className="nav-overview-list">
            {triggerAnimationMenu &&
              menuList.map((menu, index) => (
                <li
                  key={index}
                  className={`nav-overview-list--item${
                    menu.order === currentMenu?.order ? ' active' : ''
                  }`}
                  onClick={() => onChangeMenu(menu)}
                >
                  <ArrowRightIcon className="nav-overview-list--item-icon" />
                  {menu.name}
                </li>
              ))}
          </ul>
        </div>
        <div className="nav-content">
          <div className="nav-content-header mobile-only">
            <div className="nav-content-back-button" onClick={backToMenuList}>
              <ArrowRightIcon className="back-icon" />
            </div>
            <div className="nav-content-header-title">
              {currentMenu?.name && (
                <h1 className="nav-content-header-title">{currentMenu.name}</h1>
              )}
            </div>
          </div>
          <ul className="nav-content-list">
            {triggerAnimationMenu &&
              currentMenu?.scenes?.map((scene, index) => (
                <li
                  key={index}
                  className={`nav-content-list--item slide-in${
                    scene.id === currentScene.id &&
                    scene.groupId === currentScene.groupId
                      ? ' active'
                      : ''
                  }`}
                  onClick={() => onChangeScene(scene.groupId, scene.id)}
                >
                  <div className="nav-content-list--item-dot"></div>
                  {scene.title}
                </li>
              ))}
          </ul>
        </div>
        <div className="nav-social-links mobile-only">
          <ExternalLink />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  json: state.json,
  currentScene: state.currentScene,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AvoriazMenu);
