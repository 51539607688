import React, { useEffect, useState, useCallback } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';

import { useIsMobile } from 'common/hooks';
import { setAudioAutoPlay } from 'store/actions';

import MobileExperience from './MobileExperience';
import DesktopExperience from './DesktopExperience';
import LanguageSelect from './LanguageSelect';
import ExperienceButton from './ExperienceButton';

import { INTRO_STEP } from 'consts';

import './style.scss';

const playAudio = (el) => {
  try {
    for (const key in el) {
      if (Object.hasOwnProperty.call(el, key)) {
        const element = el[key];
        element.play();
      }
    }
  } catch (error) {
    // console.error(error);
  }
};

const triggerPlayAudio = () => {
  if (!window.globalAudioPlayed) {
    playAudio(document.getElementById('globalAudio'));
    if (window.tourAudioPlayed) {
      playAudio(document.getElementById('tourAudio'));
    }
    if (window.haveAudioSrc) {
      playAudio(document.getElementsByClassName('sceneAudio'));
    }
  }
};

export default function ResumeExperience(props) {
  const {
    onUpdate,
    spinnerVisible,
    setAutoRotate,
    isFirstTime,
    setIsFirstTime,
    isSimpleStyle
  } = props;
  const [enabled, setEnabled] = useState(true);
  const isMobile = useIsMobile();
  const [step, setStep] = useState(INTRO_STEP.RENDER);
  const { viewMode } = useSelector((state) => state);
  const { autoPlayMode } = useSelector((state) => state);
  const dispatch = useDispatch();

  const onHide = useCallback(() => {
    setEnabled(false);
    setStep(INTRO_STEP.FADING_OUT);
    dispatch(setAudioAutoPlay());
  }, [dispatch]);

  const onClickHide = useCallback(() => {
    isFirstTime && setAutoRotate(false);
    if (enabled) {
      triggerPlayAudio();
      onHide();
    }
  }, [enabled, isFirstTime, onHide, setAutoRotate]);

  const triggerClickHide = useCallback(() => {
    isFirstTime && setAutoRotate(false);
    if (enabled) {
      onHide();
    }
  }, [enabled, isFirstTime, onHide, setAutoRotate]);

  useEffect(() => {

    if(isSimpleStyle) onClickHide()

    return () => {
      setIsFirstTime(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (step === INTRO_STEP.FADING_IN) {
      setStep(() => INTRO_STEP.FADED_IN);
    }
    if (step === INTRO_STEP.FADING_OUT) {
      setStep(() => INTRO_STEP.FADED_OUT);
    }
    onUpdate(step);
  }, [step, onUpdate]);

  useEffect(() => {
    if (!spinnerVisible && step === INTRO_STEP.RENDER) {
      setStep(INTRO_STEP.FADING_IN);
    }
  }, [step, spinnerVisible]);

  useEffect(() => {
    (viewMode === 'preview' || viewMode === 'no-ui') && triggerClickHide();
    if ((viewMode === 'no-intro' && isFirstTime) || autoPlayMode) {
      requestAnimationFrame(() => {
        // triggerPlayAudio();
        onHide();
        requestAnimationFrame(() => {
          setIsFirstTime(false);
        });
      });
    }
  }, [
    autoPlayMode,
    isFirstTime,
    triggerClickHide,
    onHide,
    setIsFirstTime,
    viewMode,
  ]);

  return (
    <div className="experience-container">
      <ExperienceButton onClickHide={onClickHide} isFirstTime={isFirstTime} />
      {isMobileOnly || isMobile ? <MobileExperience /> : <DesktopExperience />}
      <LanguageSelect />
    </div>
  );
}
