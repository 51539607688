import REGEX from 'consts/regex.const';
import { Vector3 } from 'three';
import { BOX_WIDTH } from './constant';

export function ToVector(x, y, z) {
  return { x, y, z };
}

export function getCrossProductTwoVectors(vec1, vec2) {
  return {
    x: vec1.y * vec2.z - vec1.z * vec2.y,
    y: vec1.z * vec2.x - vec1.x * vec2.z,
    z: vec1.x * vec2.y - vec1.y * vec2.x,
  };
}

export function cosTwoVectors(a, b) {
  return (
    (a.x * b.x + a.y * b.y + a.z * b.z) /
    (Math.sqrt(Math.pow(a.x, 2) + Math.pow(a.y, 2) + Math.pow(a.z, 2)) *
      Math.sqrt(Math.pow(b.x, 2) + Math.pow(b.y, 2) + Math.pow(b.z, 2)))
  );
}

export function calcAngleBetweenVectors(a, b) {
  const cosAngle = cosTwoVectors(a, b);
  const angle = Math.acos(cosAngle);
  return angle;
}

export function calcRotationY(vec1, vec2) {
  const crossProduct = getCrossProductTwoVectors(vec1, vec2);

  // Use cross product to determine rotation direction
  if (crossProduct.y < 0) {
    return Math.acos(cosTwoVectors(vec1, vec2));
  } else if (crossProduct.y > 0) {
    return -Math.acos(cosTwoVectors(vec1, vec2));
  }

  return 0;
}

export function calcRotationDegree(vec1, vec2) {
  const rad = calcRotationY(vec1, vec2);
  return (rad * 180) / Math.PI;
}

export function calcCamRotationY(camPosition1, camPosition2) {
  const currentCam = {
    x: camPosition1[0],
    y: 0,
    z: camPosition1[2],
  };

  const targetCam = {
    x: camPosition2[0],
    y: 0,
    z: camPosition2[2],
  };

  return calcRotationY(currentCam, targetCam);
}

export function calcFootRotationY([x, , z]) {
  const projectOnXZ = { x, y: 0, z };
  const unitVectorZAxis = { x: 0, y: 0, z: 1 };
  const angle = calcAngleBetweenVectors(projectOnXZ, unitVectorZAxis);
  let angleRotate = 0;
  if (x < 0) {
    angleRotate = -angle + Math.PI;
  }
  if (x > 0) {
    angleRotate = angle + Math.PI;
  }
  if (x === 0 && z > 0) {
    angleRotate = Math.PI;
  }

  return angleRotate;
}

export const updateVector3OrPointByRotationXZ = (
  pointArr,
  rad,
  isVec3 = true
) => {
  const [x, y, z] = pointArr;
  const x_new = x * Math.cos(rad || 0) + z * Math.sin(rad || 0);
  const z_new = z * Math.cos(rad || 0) - x * Math.sin(rad || 0);
  if (isVec3) {
    return new Vector3(x_new, 0, z_new);
  } else {
    return [x_new, y, z_new];
  }
};

export const calcScaleForProjection = (yFootPos) => {
  const topScale = 0,
    bottomScale = 1.25;
  const scaleUnit = (topScale - bottomScale) / 100;
  const halfBox = BOX_WIDTH / 2;
  const bottomY = -halfBox,
    topY = 0;
  const percentY = ((yFootPos + halfBox) / (topY - bottomY)) * 100;
  const currentScale = bottomScale + percentY * scaleUnit;
  return { scaleX: currentScale, scaleY: currentScale };
};

export const calcDisTwoPoints = (point1, point2) => {
  const dx = point1[0] - point2[0];
  const dy = point1[1] - point2[1];
  const dz = point1[2] - point2[2];
  return Math.sqrt(dx * dx + dy * dy + dz * dz);
};

export const getCenterScreen = () => ({
  x: window.innerWidth / 2,
  y: window.innerHeight / 2,
});

export const measureElement = (node, dimension, setValue) => {
  if (node !== null) {
    const rect = node.getBoundingClientRect();
    let value = null;
    if (dimension === 'width') {
      value = rect.width;
    } else if (dimension === 'height') {
      value = rect.height;
    }
    setValue(value);
  }
};

export const getImageRatio = (imgUrl) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imgUrl;

    img.onload = () => {
      const width = img.width;
      const height = img.height;
      const ratio = width / height;
      resolve(ratio);
    };

    img.onerror = () => {
      reject(new Error('Unable to load image.'));
    };
  });
};

export const parseConfigsData = (data) => {
  if (!data) return;
  return JSON.parse(data);
};

export const checkVideoUrl = (videoUrl) => {
  switch (true) {
    case REGEX.VIMEO_URL.test(videoUrl):
      return 'Vimeo';
    case REGEX.YOUTUBE_URL.test(videoUrl):
      return 'Youtube';
    default:
      return '';
  }
};

export const getYoutubeVideoId = (url) => {
  const match = url.match(REGEX.EXTRACT_YOUTUBE_ID);
  return match && match[7].length === 11 ? match[7] : false;
};

export const getVimeoId = (url) => {
  const match = url.match(REGEX.VIMEO_URL);
  return match[1] ?? false;
};

export const formatScenes = (media, scenes) => {
  const mapFloorPlanItems = media.filter(
    (item) => item.type === 'MapFloorPlan'
  );
  const allObjects = mapFloorPlanItems.flatMap((item) =>
    item.mapFloorPlan.flatMap((floorPlan) =>
      floorPlan.objects.map((object) => ({ ...object, parentId: item.id }))
    )
  );

  const newScenes = scenes.map((itemB) => {
    const matchingObjects = allObjects.filter(
      (object) => object.id === itemB._id
    );

    const mapFloorPlans = mapFloorPlanItems.filter((map) =>
      matchingObjects.some((object) => map.id === object.parentId)
    );

    const isHaveMap = itemB.hotspots.some((map) =>
      mapFloorPlans.some((floorPlan) => map.id === floorPlan.id)
    );

    if (matchingObjects.length) {
      return {
        ...itemB,
        isShowMapFloorPlan: true,
        hotspots: isHaveMap
          ? itemB.hotspots
          : [...itemB.hotspots, ...mapFloorPlans],
      };
    } else {
      return { ...itemB, isShowMapFloorPlan: false };
    }
  });
  return newScenes;
};

export const backToDefaultScene = (menuList, scenes, onSelect) => {
  let defaultScene = scenes.find((scene) => scene.isDefault);

  if (!defaultScene) {
    const firstScene = menuList[0].scenes[0];
    defaultScene = scenes.find((scene) => scene.id === firstScene.id);
  }

  onSelect(defaultScene.groupId, defaultScene.id);
};
