import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useThree } from '@react-three/fiber';
import { useDispatch } from 'react-redux';
import { degToRad } from 'three/src/math/MathUtils';
import CrossfadeMaterial from '../cube360/materials/material';
import { createTexture } from '../scene.utils';
import { setLimitOrbit } from 'store/actions';
import { cleanLoadTexture } from 'utils/textureHelper';
import { EVENT_TYPE, trackEventByType } from 'gaTracking';
// import gsap from 'gsap';
import { getCameraPositionFromLookAtCoordinates } from 'utils/positionHelper';
import configs from 'configs';

const SPHERE_RADIUS = 400;
const DEFAULT_ARG = [SPHERE_RADIUS, 128, 64];

const Sphere360 = (props) => {
  const { sceneData, onShowHotspots, onSDLoaded, onHDLoaded } = props;
  const meshRef = useRef();
  const { camera } = useThree();
  // const [rot, setRot] = useState([0, degToRad(90), 0]);
  const [texture0, setTexture0] = useState(createTexture());
  const [texture1, setTexture1] = useState(createTexture());
  const [mixFactor, setMixFactor] = useState(0);
  const [opacity, setOpacity] = useState(0);

  const processQueue = useRef(false);

  // const { viewMode, worldParams } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [objArgs, setObjArgs] = useState(DEFAULT_ARG);
  const argUpdated = useRef(false);

  // const linearUpdate = (newPos) => {
  //   const position = camera.position.clone();
  //   // : { x: 0, y: 0, z: 1 };
  //   gsap.to(position, {
  //     x: newPos.x,
  //     y: newPos.y,
  //     z: newPos.z,
  //     duration: configs.crossfadeSpeed,
  //     onUpdate: () => {
  //       camera.position.set(position.x, position.y, position.z);
  //     },
  //     onComplete: () => {
  //       props.onChangeLookAt();
  //       // props.toggleHideFootSteps(false);
  //     },
  //   });
  // };

  const animateCamera = ([x, y, z]) => {
    const newPos = getCameraPositionFromLookAtCoordinates(x, y, z);
    // linearUpdate(newPos);
    camera.position.set(newPos.x, newPos.y, newPos.z);
    camera.fov = window.panoFov || 45;
    camera.updateProjectionMatrix();
    window.camera = camera;
    props.onChangeLookAt();
  };

  const loadSceneImage = (mf) => {
    // stop all-loading
    processQueue.current = false;
    const { id, PanoImage, defaultOrientation } = sceneData;
    if (!PanoImage?.sdImageUrl) return;
    // load SD images for the scene
    loadImageAsTexture(PanoImage.sdImageUrl, id, mf)
      .then(onSDLoaded)
      // start preload linked SD images
      // .then(() => this.getLinkedImages())
      // show orientation
      .then(() => animateCamera([...defaultOrientation]))
      // .then(this.cleanHdCache)
      .then(() => onShowHotspots())
      // .then(() => {
      //   if (id !== pano.id) {
      //     return null;
      //   }
      //   return this.loadLinkedImages();
      // })
      .then(() => window.waitASecond(3))
      .then(() => {
        window.logMessage('load & render MD');
        return loadImageAsTexture(
          PanoImage.hdImageUrl || PanoImage.imageUrl,
          id,
          mf
        );
      })
      .then(onHDLoaded)
      .then(() => window.logMessage('load & render MD: DONE'));
    // start preload other scenes SD images
    // .then(() => {
    //   if (id !== pano.id) {
    //     return null;
    //   }
    //   return this.loadNonLinkedImages();
    // });
    // GA Tracking
    trackEventByType(EVENT_TYPE.SCENE, id);
  };

  useEffect(() => {
    if (sceneData.panoType === 'Pano') {
      loadSceneImage(mixFactor === 0 ? 1 : 0);
      if (!meshRef.current.visible) {
        meshRef.current.visible = true;
      }
      setOpacity(1);
      window.isIntroAnimation = false;
    } else {
      if (meshRef.current.visible) {
        dispatch(setLimitOrbit(null));
        camera.fov = window.defaultFov;
        camera.updateProjectionMatrix();
        setOpacity(0);
        setTimeout(() => {
          meshRef.current.visible = false;
        }, configs.transitionSpeed * 1000);
      }
    }
    // eslint-disable-next-line
  }, [camera, dispatch, sceneData, meshRef]);

  const reloadTextureState = useCallback((texture, mf) => {
    const setFunc = mf === 0 ? setTexture0 : setTexture1;
    setFunc(texture);
    setMixFactor(mf);
  }, []);

  const loadImageAsTexture = (imgUrl, panoId, mf) => {
    if (panoId !== sceneData.id) {
      return null;
    }
    const time = new Date();

    return new Promise((resolve) => {
      cleanLoadTexture(imgUrl, window.maxAnisotropy).then((res) => {
        if (panoId === sceneData.id) {
          window.logMessage(
            `Finished loading low. Tooks ${(new Date() - time) / 1000}s`
          );
          reloadTextureState(res, mf);
          resolve(res);
        }
      });
    });
  };

  useEffect(() => {
    if (argUpdated.current || !sceneData) return;
    if (sceneData.panoType === 'Pano' && sceneData.PanoImage) {
      const { width, height } = sceneData.PanoImage;
      const phiLength = 2 * Math.PI * 0.9;
      const phiStart = (2 * Math.PI - phiLength) / 2;
      const thetaLength = (phiLength * height) / width;
      const thetaStart = (Math.PI - thetaLength) / 2;
      setObjArgs([
        SPHERE_RADIUS,
        128,
        64,
        phiStart,
        phiLength,
        thetaStart,
        thetaLength,
      ]);
      camera.fov = window.panoFov || 45;
      camera.updateProjectionMatrix();
      argUpdated.current = true;
    } else {
      setObjArgs(DEFAULT_ARG);
    }
  }, [camera, sceneData]);

  useEffect(() => {
    const [, , , phiStart, phiLength, thetaStart, thetaLength] = objArgs;
    if (
      phiStart !== undefined &&
      phiLength !== undefined &&
      thetaStart !== undefined &&
      thetaLength !== undefined
    ) {
      const horizontalFOV =
        2 *
        Math.atan(Math.tan((camera.fov * Math.PI) / 180 / 2) * camera.aspect);
      const tile = 0.75;
      const limitVer = degToRad(40) * tile;
      const limitHoz = horizontalFOV * tile;
      dispatch(
        setLimitOrbit({
          minPolarAngle: thetaStart + limitVer,
          maxPolarAngle: thetaStart + thetaLength - limitVer,
          minAzimuthAngle: phiStart - Math.PI + limitHoz,
          maxAzimuthAngle: phiStart + phiLength - Math.PI - limitHoz,
        })
      );
      // setRot([0, degToRad(-90), 0]);
    }
  }, [dispatch, objArgs, camera]);

  return (
    <mesh
      ref={meshRef}
      position={[0, 0, 0]}
      rotation={[0, degToRad(-90), 0]}
      scale-x={-1}
      renderOrder={3}
    >
      <sphereBufferGeometry attach="geometry" args={objArgs} />
      <CrossfadeMaterial
        texture1={texture0}
        texture2={texture1}
        mixFactor={mixFactor}
        opacity={opacity}
      />
    </mesh>
  );
};

export default Sphere360;
