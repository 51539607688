import React from 'react';
import * as THREE from 'three';
import { connect } from 'react-redux';
import flatten from 'lodash/flatten';
import { trackEventByType, EVENT_TYPE } from 'gaTracking';

import { cleanLoadTexture, cleanLoadTextures } from 'utils/textureHelper';
import CubeEffect from './cubeEffect';
import { SIDE } from './constants';
import { isPhone, isTablet } from 'deviceDetect';
import {
  applySceneTexture,
  createTexture,
  getCubeCanvases,
  preloadImages,
} from '../scene.utils';

const cubeMaterials = {
  px: null,
  nx: null,
  py: null,
  ny: null,
  pz: null,
  nz: null,
};

class Cube360 extends React.Component {
  queue = [];
  toClean = [];
  linkedScenes = [];
  nonLinkedScenes = [];
  processQueue = false;
  mixFactor = 1;

  constructor(props) {
    super(props);
    this.state = {
      canvas0: getCubeCanvases(),
      canvas1: getCubeCanvases(),
      texture0: { ...cubeMaterials },
      texture1: { ...cubeMaterials },
      curMixFactor: 0,
    };

    this.state.texture0 = {
      px: createTexture(),
      nx: createTexture(),
      py: createTexture(),
      ny: createTexture(),
      pz: createTexture(),
      nz: createTexture(),
    };

    this.state.texture1 = {
      px: createTexture(),
      nx: createTexture(),
      py: createTexture(),
      ny: createTexture(),
      pz: createTexture(),
      nz: createTexture(),
    };
  }

  componentDidMount() {
    if (this.props.pano?.id && this.props.pano.panoType !== 'Pano') {
      this.loadImages(this.mixFactor);
    }
  }

  componentDidUpdate(preProps) {
    if (
      this.props.pano?.id &&
      (preProps.pano?.id !== this.props.pano?.id ||
        preProps.stepHandlers.active !== this.props.stepHandlers.active)
    ) {
      if (this.props.pano.panoType !== 'Pano') {
        this.mixFactor = this.mixFactor ? 0 : 1;
        this.loadImages(this.mixFactor);
      }
    }

    if (
      preProps.stepHandlers.active !== this.props.stepHandlers.active &&
      !this.props.stepHandlers.active
    ) {
      this.processQueue = false; // to stop loading images
      this.linkedScenes = [];
      this.nonLinkedScenes = [];
    }
  }

  loadImages = (mixFactor) => {
    // stop all-loading
    this.processQueue = false;
    const { id, cubeMapImages, defaultOrientation } = this.props.pano;
    // load SD images for the scene
    // this.loadCanvasTextures(cubeMapImages.size1024, id, mixFactor, true)
    this.loadImageAsTextures(cubeMapImages.size1024, id, mixFactor, true)
      .then(this.props.onSDLoaded)
      // start preload linked SD images
      .then(() => this.getLinkedImages())
      // show orientation
      .then(() => this.props.animateCamera([...defaultOrientation]))
      .then(this.cleanHdCache)
      .then(() => this.props.onShowHotspots())
      .then(() => {
        if (id !== this.props.pano.id) {
          return null;
        }
        return this.loadLinkedImages();
      })
      .then(() => window.waitASecond(3))
      .then(() => {
        window.logMessage('load & render MD');
        return this.loadImageAsTextures(cubeMapImages.size2048, id, mixFactor);
      })
      .then(this.props.onHDLoaded)
      .then(() => window.logMessage('load & render MD: DONE'))
      .then(() => window.waitASecond(2))
      .then(() => {
        if (isPhone() || isTablet()) {
          return;
        }
        window.logMessage('load & render HD');
        return this.loadImageAsTextures(cubeMapImages.size4096, id, mixFactor);
      })
      .then(() => window.logMessage('load & render HD: DONE'))
      // start preload other scenes SD images
      .then(() => {
        if (id !== this.props.pano.id) {
          return null;
        }
        return this.loadNonLinkedImages();
      });
    // GA Tracking
    trackEventByType(EVENT_TYPE.SCENE, id);
  };

  getLinkedImages = () => {
    const { id, steps } = this.props.pano;
    const linkedStepSceneIds = (steps || []).map((s) => s.targetSceneId);
    const linkedHotspotSceneIds = (this.props.pano.hotspots || [])
      .filter((h) => h.sceneLinkId)
      .map((h) => h.sceneLinkId);
    const linkedSceneIds = [...linkedHotspotSceneIds, ...linkedStepSceneIds];
    const panoList = this.props.scenes.filter((p) => p.id !== id);
    const hotspotsSceneList = panoList.filter(
      (p) => linkedSceneIds.indexOf(p._id) !== -1
    );
    const menuPano = this.props.menuJson.map((g) => g.scenes);
    const menuPanoId = flatten(menuPano).map((p) => p.id);
    const menuPanoList = this.props.scenes.filter(
      (p) => menuPanoId.indexOf(p.id) !== -1
    );
    this.linkedScenes = [...menuPanoList, ...hotspotsSceneList];
    this.nonLinkedScenes = panoList.filter(
      (p) => !this.linkedScenes.find((s) => s._id === p._id)
    );
  };

  loadLinkedImages = () => {
    this.queue = [...this.linkedScenes];
    this.processQueue = true;
    return this.loadQueue();
  };

  loadNonLinkedImages = () => {
    this.queue = [...this.nonLinkedScenes];
    return this.loadQueue();
  };

  handleQueue = (onCompleted) => {
    if (!this.processQueue) return onCompleted(false);
    if (this.queue.length === 0) return onCompleted(true);

    const currQueueScene = this.queue.pop();
    const { cubeMapImages, PanoImage } = currQueueScene;

    let imgForPreload = [];
    if (cubeMapImages.size1024) {
      imgForPreload.push(...cubeMapImages.size1024);
    }
    if (PanoImage?.sdImageUrl) {
      imgForPreload.push(PanoImage.sdImageUrl);
    }

    preloadImages(imgForPreload)
      .then(() => this.handleQueue(onCompleted))
      .catch(() => this.handleQueue(onCompleted));
  };

  loadQueue = () => {
    return new Promise((resolve) => {
      this.handleQueue(resolve);
    });
  };

  loadCanvasTextures = async (imgs, panoId, mixFactor, allAtOnce = false) => {
    if (panoId !== this.props.pano.id) {
      return null;
    }
    const canvases = mixFactor === 0 ? this.state.canvas0 : this.state.canvas1;
    const textures =
      mixFactor === 0 ? this.state.texture0 : this.state.texture1;

    await applySceneTexture(
      canvases,
      textures,
      imgs,
      window.maxAnisotropy,
      allAtOnce
    );
    if (allAtOnce) {
      this.setState({ curMixFactor: mixFactor });
    }
  };

  loadImageAsTextures = (imgs, panoId, mixFactor, allAtOnce = false) => {
    if (panoId !== this.props.pano.id) {
      return null;
    }
    window.logMessage(`Start loading ${allAtOnce ? 'low' : 'high'}`);
    const time = new Date();
    if (allAtOnce) {
      return new Promise((resolve) => {
        cleanLoadTextures(imgs, window.maxAnisotropy).then((res) => {
          if (panoId === this.props.pano.id) {
            window.logMessage(
              `Finished loading low. Tooks ${(new Date() - time) / 1000}s`
            );
            this.reloadTextureState(res, mixFactor, () => {
              resolve(res);
            });
          }
        });
      });
    } else {
      const reqs = imgs.map(
        (img, index) =>
          new Promise((resolve) => {
            cleanLoadTexture(img, window.maxAnisotropy)
              .then((texture) => {
                window.logMessage(
                  `Img ${img} tooks ${(new Date() - time) / 1000}s to load`
                );
                if (panoId === this.props.pano.id) {
                  this.setState(
                    {
                      [`texture${mixFactor}`]: {
                        ...this.state[`texture${mixFactor}`],
                        [SIDE[index]]: texture,
                      },
                    },
                    () => resolve(texture)
                  );
                } else {
                  resolve(texture);
                }
              })
              .catch(() => resolve());
          })
      );
      return new Promise((resolve) => {
        Promise.all(reqs).then((res) => {
          if (panoId === this.props.pano.id) {
            window.logMessage(
              `Finished loading high. Tooks ${(new Date() - time) / 1000}s`
            );
            resolve(res);
            this.toClean = [...imgs];
          }
        });
      });
    }
  };

  // reloadState = (textures, mixFactor, callBack) => {
  //   const materialKey = `material${mixFactor}`;
  //   const material = { ...this.state[materialKey] };
  //   for (let i = 0; i < 6; i++) {
  //     material[SIDE[i]] = textures[i];
  //   }
  //   this.setState(
  //     { [materialKey]: { ...material }, curMixFactor: mixFactor },
  //     () => {
  //       callBack();
  //       window.logMessage(`Finished rendering textures`);
  //     }
  //   );
  // };

  reloadTextureState = (textures, mixFactor, callBack) => {
    const textureKey = `texture${mixFactor}`;
    const textureObj = { ...this.state[textureKey] };
    for (let i = 0; i < 6; i++) {
      textureObj[SIDE[i]] = textures[i];
    }
    this.setState(
      { [textureKey]: { ...textureObj }, curMixFactor: mixFactor },
      () => {
        callBack();
        window.logMessage(`Finished rendering textures`);
      }
    );
  };

  cleanHdCache = () => {
    // window.logMessage('remove previous scene high textures from cache');
    while (this.toClean.length) {
      const img = this.toClean.pop();
      THREE.Cache.remove(img);
    }
  };

  getCurrentIdIndex = () => `${this.props.pano.id}-0`;

  render() {
    const { curMixFactor, texture0, texture1 } = this.state;

    return (
      <CubeEffect
        stepHandlers={this.props.stepHandlers}
        tripodMarkerUrl={this.props.tripodMarkerUrl}
        texture0={texture0}
        texture1={texture1}
        mixFactor={curMixFactor}
        pano={this.props.pano}
      />
    );
  }
}
const mapStateToProps = ({ json }) => ({
  menuJson: json.menu,
});

export default connect(mapStateToProps)(Cube360);
